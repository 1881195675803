import { AwsFile, awsS3Client } from '../../aws/aws-s3-client';
import { Environment } from '../../env';
import { FileUpload, Upload } from '@components/upload/Upload';
import { MAX_FILE_SIZE_BYTES } from '@utils/constants';
import { OrganizationDTO } from '@unternehmensverzeichnis/shared/types';
import { UploadStatus } from '@components/upload/UploadStatus';
import React, { useCallback, useEffect, useState } from 'react';

export const UploadCompanyLogo = ({
    value,
    setValue,
    updateCompanyLogo,
    initialValues,
}: {
    value?: string | null;
    setValue: (url: string | null) => void;
    updateCompanyLogo: (orgData: OrganizationDTO) => Promise<Response>;
    initialValues: OrganizationDTO;
}) => {
    const [currentFile, setCurrentFile] = useState<AwsFile | null>(null);
    const [fileUploads, setFileUploads] = useState<FileUpload<string>[]>(
        currentFile
            ? [
                  {
                      status: UploadStatus.PREEXISTING,
                      name: currentFile.name,
                      size: currentFile.size,
                      progress: 1,
                      id: 0,
                      remoteId: currentFile.id,
                      file: new File([], ''),
                  },
              ]
            : [],
    );

    useEffect(() => {
        if (value) {
            const fetchFile = async () => {
                const file = await awsS3Client.getFile(
                    value,
                    Environment.AWS_FILE_BUCKET,
                );
                if (file) {
                    setCurrentFile(file);
                    setFileUploads([
                        {
                            status: UploadStatus.PREEXISTING,
                            name: file.name,
                            size: file.size,
                            progress: 1,
                            id: 0,
                            remoteId: file.id,
                            file: new File([], ''),
                        },
                    ]);
                } else {
                    setFileUploads([]);
                }
            };
            void fetchFile();
        }
    }, []);

    const uploadFuncDb = useCallback(
        async (file: File, progressCallback: (progress: number) => void) => {
            const awsFile = await awsS3Client.uploadFile(
                file,
                Environment.AWS_FILE_BUCKET,
            );
            if (!awsFile) {
                return Promise.reject('Error uploading file');
            }
            progressCallback(1);
            setValue(awsFile.url);
            setCurrentFile(awsFile);
            await updateCompanyLogo({
                ...initialValues,
                companyLogoUrl: awsFile.url,
            });
            return Promise.resolve(awsFile.id);
        },
        [setValue, value, initialValues],
    );

    const deleteFunc = useCallback(
        async (awsFileId: string) => {
            await awsS3Client.deleteFile(
                awsFileId,
                Environment.AWS_FILE_BUCKET,
            );
            setValue(null);
            setCurrentFile(null);
            await updateCompanyLogo({
                ...initialValues,
                companyLogoUrl: undefined,
            });
        },
        [setValue, initialValues],
    );

    return (
        <div>
            <h3 className="mb-1 font-normal">Firmenlogo</h3>
            {currentFile && (
                <img
                    src={currentFile.url}
                    alt="company logo"
                    className="max-h-[96px] mb-4 mx-4"
                />
            )}
            <Upload
                fileUploads={fileUploads}
                setFileUploads={setFileUploads}
                deleteFuncDB={deleteFunc}
                maxFileSize={MAX_FILE_SIZE_BYTES}
                maxFiles={1}
                uploadFuncDB={uploadFuncDb}
                acceptedFileFormats={{
                    'image/png': ['.png'],
                    'image/jpeg': ['.jpg', '.jpeg'],
                }}
            />
        </div>
    );
};
